thead[class*="ant-table-thead"] th {
  background:   #dbdbdb;
  font-size: 16px;
  font-weight: bold;
  color: #140035;
}

.ant-table-tbody > tr > td {
  max-width: 200px;
  overflow: hidden;
  color: #140035;
  
  border: 1px solid rgba(179, 179, 179, 0.5);
}

.ant-table-row {
  border: 1px solid rgba(179, 179, 179, 0.5);
}

.rowDisabled {
  background-color: #dddddd !important;
}

.ant-pagination-item-active {
  border-width: 2px;
  border-color: #140035;
  font-weight: bold;
}

a {
  color: #140035;
}
